<template>
  <div>
    <!-- #region Form -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3"> {{ $t("mediaKindEditPage.pageTitle") }}</strong>
          </CCardHeader>
          <CCardBody>

            <span class="caption">
              {{ $t("common.editCaption") }}
            </span>
            <CForm class="submit_form">
              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("common.name") }}</strong></CCol>
                <CCol>
                  <CInput v-model="editJa.name" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.editJa.name.$dirty &&
                      !$v.editJa.name.required
                    "
                  >
                    {{ $t("validations.required", { vName: $t("common.name")})}}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.editJa.name.$dirty &&
                      !$v.editJa.name.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("common.name"),vMax: $v.editJa.name.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region 表示設定 -->
              <CRow>
                <CCol class="label"><strong>{{ $t("mediaKindEditPage.displayable") }}</strong></CCol>
                <CCol sm="2">
                  <CSelect
                    :value.sync="editJa.displayable"
                    :options="isDisplayEnabled"
                  />
                </CCol>
              </CRow>
              <!-- #endregion 表示設定 -->

              <!-- #region 説明 -->
              <CRow>
                <CCol class="label"><strong>{{ $t("common.description") }}</strong></CCol>
                <CCol>
                  <CTextarea
                    class="mb-2"
                    v-model="editJa.description"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.editJa.description.$dirty &&
                      !$v.editJa.description.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("common.description"),vMax: $v.editJa.description.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明 -->

              <!-- #endregion 入力フィールド -->

              <!-- #region 更新ボタン -->
              <CButton
                class="px-5 mt-4 float-left"
                color="info"
                @click="onUpdateClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.update") }}
              </CButton>
              <!-- #endregion 更新ボタン -->

            </CForm>
          </CCardBody>

          <CCardFooter>
            <CButton color="secondary" class="" @click="onBackClicked()"
              ><i class="icon cil-arrow-left mr-2"></i
              >{{ $t("common.back") }}</CButton
            >
          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Form -->

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
      :car=[]
    />

    <SuccessModal
      :successModal="deleteSuccessModal"
      @close="deleteSuccessModal = $event"
      :car=[]
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />

  </div>
</template>

<script>
import i18n from "@/i18n";

import {
  required,
  // numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "MediaKindEdit",

  computed: {
    activeStatus() {
      return this.$v.editJa.$invalid ? "inactive" : "active";
    },

    showSuccessModal() {
      return this.successModal || this.deleteSuccessModal;
    }
  },

  data() {
    return {
      //#region Flag
      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,
      deleteSuccessModal: false,
      //#endregion Flag
      mediaKindId:this.$route.params.mediaKindId,

      // getType:[],

      //#region Message
      errorMessage: "error",
      //#endregion Message

      //#region request
      // 日本語入力データ
      editJa: {
        language: "ja",
        name: "",
        description: "",
        displayable:false
      },
      // editZh:{
      //   language: "zh",
      //   name: "",
      //   description: ""
      // },
      // editEn:{
      //   language: "en",
      //   name: "",
      //   description: ""
      // },

      requests:[],
      //#endregion request

      //#region Response data
      response: [],
      reponseObj:{},
      //#endregion Response data

      isDisplayEnabled: [
        {
          value: false,
          label: `${i18n.t("mediaKindEditPage.isDisplayEnabled.disable")}`,
        },
        {
          value: true,
          label: `${i18n.t("mediaKindEditPage.isDisplayEnabled.enable")}`,
        },
        
      ],
    };
  },

  validations() {
    return {
      editJa: {
        name: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(1000),
        },
      },
    };
  },

  methods: {

    //#region Event

    /** 削除ボタン押下 */
    onDeleteClicked() {
      this.deleteConfirmModal = true;
    },

    /** 削除ボタン押下 */
    onDeleteSubmit() {
      this.reqDelete();
    },

    /** 更新ボタン押下 */
    onUpdateClicked() {
      this.$v.editJa.$invalid ?
        this.$v.editJa.$touch() :
        this.reqPut();
    },

    //#endregion Event

    //言語変換
    // languageConvert(datas){
    //   datas.forEach(element => {
    //     if(element.language === this.$store.state.currentLanguage){
    //       this.editJa.language = element.language;
    //       this.editJa.name = element.name;
    //       this.editJa.description = element.description;
    //     }
    //   });
    // },

    /**取得データのマスタータイプ取得 */
    // getMasterType(type){
    //   console.log(this.selectItems)
    //   const typeObj = this.selectItems.filter((v) => v.value === type);
    //   console.log(typeObj)

    //   this.masterTypeLabel = typeObj.label;
    // },

    /**取得データセット */ 
    dataInsert(data){
      console.log(data)

         // 日本語データ
         // NOTE:多言語用の画面でないため、日本語データだけ挿入
        if(data.language === `ja`){
          this.editJa.language = data.language;
          this.editJa.name = data.name;
          this.editJa.description = data.description;
          this.editJa.displayable = data.displayable;
        }

      // TODO:多言語対応後に以下のような処理を行う
      // datas.forEach(element => {
      //    // 日本語データ
      //   if(element.language === `ja`){
      //     this.editJa.language = element.language;
      //     this.editJa.name = element.name;
      //     this.editJa.description = element.description;
      //     this.editJa.displayable = element.displayable;
      //   }

        // //中国語データ
        // if(element.language === `zh`){
        //   this.editZh.language = element.language;
        //   this.editZh.name = element.name;
        //   this.editZh.description = element.description;
        // }

        // //英語データ
        // if(element.language === `en`){
        //   this.editEn.language = element.language;
        //   this.editEn.name = element.name;
        //   this.editEn.description = element.description;
        // }
      // });
    },

    //#region Request

    /** 取得 */
    reqGet() {

      // this.mediaKindNotFound = false;
      // this.tableItems = [];

      const callback = (a) => {
          this.response = a;
          this.dataInsert(this.response)
          this.loading = false;
      };

      const errorCallback = (err) => {
        console.log(err);
        this.loading = false;
      };

      this.reqGetImageCategory(callback,errorCallback);
    },


     /** 更新 */
    reqPut() {

      // this.requests =[];
      this.requests.push(this.editJa)
      const body = this.requests;

      const callback = () => this.successModal = true;

      const errorCallback = (err) => {
        console.log(err);
        this.errorMessage = `${err.name} : ${err.message}`;
        this.errorModal = true;
      }

      this.reqPutImageCategory(body,callback,errorCallback);
    },

    //#endregion Request


    //#region Method

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    backPage() {
      this.$router.push({
        path: "/admin/mediaKind/list"
      });
    },
    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>